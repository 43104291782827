body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-size: cover;
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.login-form h2 {
    text-align: center;
}

.login-form input[type="tel"],
.login-form input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.login-form button::after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: all 0.5s;
}

.login-form button:hover::after {
    width: 0;
    height: 0;
}

.login-form button span {
    position: relative;
    z-index: 1;
}