.dashboard {
    display: flex;
    font-family: 'Arial', sans-serif;
}

.sidebar {
    background-color: #1a1a1a;
    color: #fff;
    padding: 20px;
    width: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    font-size: medium;
    gap: 20px;
}

.sidebar h2 {
    margin-bottom: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    cursor: pointer;
    display: flex;
    /* Align icon and text in a row */
    align-items: center;
    /* Center items vertically */
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.sidebar li svg {
    margin-right: 10px;
    /* Add spacing between icon and text */
}

.sidebar li:hover {
    background-color: #333;
}

.sidebar li.active {
    background-color: #555;
}

.content {
    flex-grow: 1;
    padding: 20px;
    background-color: #fff;
    /* Set the background color to white */
}

.navbar {
    background-color: #E4E4E4;
    color: #000;
    /* Changed text color to black */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.main-content {
    background-color: #E4E4E4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.sidebar button.logout-button {
    cursor: pointer;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: #1a1a1a;
    color: #fff;
    transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    position: absolute;
    bottom: 10vh;
}

.sidebar button.logout-button:hover {
    background-color: #333;
    color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.sidebar button.logout-button:active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.sidebar button.logout-button:focus {
    outline: none;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 999;
    text-align: center;
    transition: box-shadow 0.5s;
}

.popup p {
    margin-bottom: 20px;
    color: #000;
    font-size: large;
}

.popup button {
    margin: 0 10px;
    padding: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.popup button.confirm {
    background-color: #000;
    color: #fff;
}

.popup button.cancel {
    background-color: #000;
    color: #fff;
}

.popup button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}